<!-- 房间管理  By阿旭-->
<template>
  <div class="main">
    <div class="top_btn">
      <div class="btnbox">
        <!--<el-select @change="queryChange" v-model="queryvalue" size="medium" placeholder="状态">-->
        <!--  <el-option-->
        <!--    v-for="item in options"-->
        <!--    :key="item.value"-->
        <!--    :label="item.label"-->
        <!--    :value="item.value"-->
        <!--  ></el-option>-->
        <!--</el-select>-->

<!--        v-if="roles.code === 'HOTEL_ADMIN'"-->
        <el-select
          :clearable="true"
          v-btn-permission="'008003001002'"
          class="select-width"
          size="medium"
          @change="queryChange"
          v-model="isolationPointId"
          placeholder="隔离点">
          <el-option v-for="item in isolationList" :key="item.id" :label="item.orgName" :value="item.id" />
        </el-select>
        <div v-btn-permission="'008003001004'" class="btn">
          <el-button type="primary" size="small" @click="roomWarni">酒店预警</el-button>
        </div>
        <div v-btn-permission="'008003001005'" class="btn">
          <el-button type="primary" size="small" @click="piliangqidong">批量启动</el-button>
        </div>
        <div v-btn-permission="'008003001006'" class="btn">
          <el-button type="danger" size="small" @click="piliangtingyong">批量停用</el-button>
        </div>
        <div v-btn-permission="'008003001007'" class="btn">
          <el-button type="primary" size="small" @click="donwload">下载导入模板</el-button>
        </div>
        <div v-btn-permission="'008003001008'" class="btn">
          <el-upload style="margin:0 10px;"  class="btns-item upload-item" :action="upload" accept=".xls, .xlsx"
            :show-file-list="false" :headers="myHeaders" :on-success="handelSuccess">
            <el-button type="primary" size="small">导入房间</el-button>
          </el-upload>
        </div>
        <div v-btn-permission="'008003001001'" class="btn">
          <el-button type="primary" size="small" @click="addroom">新增房间</el-button>
        </div>
      </div>

<!--      <div v-if="roles.code === 'HOTEL_EMPLOYEE'" v-btn-permission="'008003001001'">-->
    </div>


    <!--统计-->
    <div class="census-box">
      <div class="census-item">
        <div class="census-title">总房数</div>
        <div class="census-num">{{ totalNum }}</div>
      </div>

      <div class="census-item">
        <div class="census-title">可用房数量</div>
        <div class="census-num">{{ usableUseRoomNum }}</div>
      </div>

      <div class="census-item">
        <div class="census-title">已用房数量</div>
        <div class="census-num">{{ useRoomNum }}</div>
      </div>

      <div class="census-item">
        <div class="census-title">未用房数量</div>
        <div class="census-num">{{ unusedRoomNum }}</div>
      </div>

      <div class="census-item">
        <div class="census-title">停用房数量</div>
        <div class="census-num">{{ stopNum }}</div>
      </div>

      <div class="census-item">
        <div class="census-title">脏房数量</div>
        <div class="census-num">{{ leaveUnusedNum }}</div>
      </div>
    </div>

    <!--楼层-->
    <div class="arrange_content_bottom">
      <div class="arrange_content_bottom_floor" v-for="(item, v) in roomListInfo" :key="v">
        <div style="padding: 6px 0;">{{ item.floorNo }}层</div>
        <div class="arrange_content_bottom_title">
          <div
            v-for="(list, d) in item.roomList"
            :key="d"
            @dblclick="arrange(list)"
            class="room-list"
            style="margin-right: 18px; margin-bottom: 12px;"
            :class="{
              'room-off': list.statusName === '停用',
              'room-normal': list.statusName === '已使用',
              'room-vacancy': list.statusName === '空置',
              'room-full': list.statusName === '满员',
              'room-dirty': list.statusName === '脏房'
            }">
            <div class="arrange_content_bottom_floor_num">{{ list.roomNo }}</div>
            <div class="arrange_content_bottom_floor_type">状态: {{ list.statusName }}</div>
            <div class="arrange-text">已入住: {{ list.livedCount }}</div>
            <div class="arrange-text" v-if="list.statusName === '脏房'">
              <span style="display: inline-block; line-height: 16px;">离开时间: {{ list.leaveTime }}</span>
            </div>
            <div class="arrange-text-name">{{ list.personNames }}</div>
            <div class="room-bubble" v-if="list.isShow && list.statusName === '脏房'">可启用</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增房间弹窗 -->
    <el-dialog v-if="isArrange" title="新增房间" :visible.sync="isArrange" width="40%">
      <el-form
        style="width:100%"
        :rules="rules"
        ref="redformRef"
        :model="redform"
        label-width="120px">
        <el-form-item class="formitem" label="楼层号：" prop="floorNo">
          <el-input style="width:90%" v-model="redform.floorNo"></el-input>
        </el-form-item>

        <el-form-item
          class="formitem"
          v-for="(domain, index) in redform.labDays"
          :label="'房间号' + (index+1) + '：'"
          :key="domain.key"
          :prop="'labDays.' + index + '.labDaysName'"
          :rules="{ required: true, message: '房间号不能为空', trigger: 'blur'}">
          <el-input style="width:66%" v-model="domain.labDaysName"></el-input>
          <el-button style="margin-left:10px" @click.prevent="removeDomain(index)">删除</el-button>
        </el-form-item>
        <el-form-item class="formitem" label="是否有窗：">
          <el-radio-group style="width:90%;" v-model="redform.windowFlag">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="formitem" label="房间状态：" prop="status">
          <el-radio-group style="width:90%;" v-model="redform.status">
            <el-radio label="1">启用</el-radio>
            <el-radio label="0">停用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="formitem" label="床位数：" prop="bedCount">
          <el-input style="width:90%" v-model="redform.bedCount"></el-input>
        </el-form-item>
        <el-form-item class="formitem" label="备注：">
          <el-input style="width:90%" v-model="redform.remark"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addDomain">添加房间号</el-button>
        <el-button size="small" @click="isArrange = false">取消</el-button>
        <el-button size="small" type="primary" @click="fjbc">保存</el-button>
      </span>
    </el-dialog>

    <!-- 修改房间弹窗 -->
    <el-dialog v-if="isArrange02" title="修改房间" :visible.sync="isArrange02" width="40%">
      <el-form
        style="width:100%"
        :rules="rules"
        ref="redformRef2"
        :model="redFormInfo"
        label-width="120px">
        <el-form-item class="formitem" label="楼层号：" prop="floorNo">
          <el-input style="width:90%" v-model="redFormInfo.floorNo"></el-input>
        </el-form-item>
        <el-form-item class="formitem" label="房间号：">
          <el-input style="width:90%" v-model="redFormInfo.roomNo"></el-input>
        </el-form-item>
        <el-form-item class="formitem" label="是否有窗：">
          <el-radio-group style="width:90%;" v-model="redFormInfo.windowFlag">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="formitem" label="房间状态：" prop="status">
          <el-radio-group style="width:90%;" v-model="redFormInfo.status">
            <el-radio label="1">启用</el-radio>
            <el-radio label="0">停用</el-radio>
            <el-radio label="2" v-if="redFormInfo.status == 2">脏房</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="formitem" label="离开时间：" v-if="redFormInfo.status == 2">
          <span style="display: inline-block; line-height: 16px;">{{ redFormInfo.leaveTime }}</span>
        </el-form-item>
        <el-form-item class="formitem" label="床位数：" prop="bedCount">
          <el-input style="width:90%" v-model="redFormInfo.bedCount"></el-input>
        </el-form-item>
        <el-form-item class="formitem" label="备注：">
          <el-input style="width:90%" v-model="redFormInfo.remark"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="isArrange02 = false">取消</el-button>
        <el-button type="primary" @click="fjbc02">修改</el-button>
      </span>
    </el-dialog>
    <!-- 酒店预警 -->
    <Dialog dialog-title="酒店预警" :is-show-dialog="roomWarniFlag" label-width="86px" dialog-width="650px" v-loading="loading"
      @closeDialog="closeDialog" @submitForm="submitFormInfo" ref="dia">
      <div style="display:flex;width:100%">
        <div style="width:50%;">
          <span style="font-weight:bold;margin-bottom: 10px;">转运酒店</span>
          <el-button style="float:right;margin:-6px 0 10px 0" type="primary" size="small" @click="add">添加</el-button>
          <el-table :data="checkRecordList" row-key="transshipmentHotelSeq" :header-cell-style="{ background: '#ffffff', color: '#909399' }" ref="table1" size="medium" 
            :max-height="400" style="width: 100%%;margin-top:10px;" border>
            <el-table-column
              label="序号"
              type="index"
              width="50">
            </el-table-column>
            <el-table-column prop="id" label="隔离点名称">
              <template slot-scope="{ row }">
                <el-select
                  :clearable="true"
                  class="select-width"
                  size="medium"
                  @change="queryChange2"
                  v-model="row.id"
                  placeholder="隔离点">
                  <el-option v-for="item in isolationList" :key="item.id" :label="item.orgName" :value="item.id" />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="labResult" label="操作" width="50">
              <template slot-scope="{ row }">
                <el-link type="danger" @click="delate(row)">删除</el-link>
              </template>
            </el-table-column>
          
          </el-table>
        </div>
        <div style="width:40%;margin-left: 20px;">
          <div>
            <span style="font-weight:bold;">预警规则</span>
            <br>
            <div style="margin:10px 0;">
              <template>
                <span style="margin-right:30px;">是否预警</span>
                <el-radio v-model="radio" :label="1">是</el-radio>
                <el-radio v-model="radio" :label="0">否</el-radio>
              </template>
            </div>
            <div style="display:flex;width: 100%;align-items: center;">
              <span>可用房间数低于</span>
              <el-input :value="number1" @input="changeValue" clearable type="text" style="width:100px;margin: 0 5px;"/>
              <span>通知</span>
            </div>
          </div>
          <div>
            <span style="font-weight:bold;">通知电话</span>
            <el-table :show-header="false" :data="checkRecordList2" :header-cell-style="{ background: '#ffffff', color: '#909399' }" size="medium" ref="table2" row-key="id"
            :max-height="400" style="width: 100%%;margin-top:10px;">
            <el-table-column>
              <template slot-scope="{ row }">
                <el-input :rules="rules" prop="phoneNum" v-model="row.phoneNumber" @change="hendledianhua" clearable type="text" style="width:100%;margin: 0 5px;"/>
              </template>
            </el-table-column>
          
          </el-table>
          </div>
        </div>
      </div>
    </Dialog>
    <!-- 批量启动 -->
    <Dialog dialog-title="批量启动" :is-show-dialog="startFlag" label-width="86px" dialog-width="640px" v-loading="loading" :flag="flagsure"
      @closeDialog="closeDialog" @submitForm="submitFormInfo2" ref="dia">
      <el-transfer
      style="text-align: left; display: inline-block"
      v-model="value1"
      filterable
      :left-default-checked="[2, 3]"
      :right-default-checked="[1]"
      :titles="['待选列表', '已选列表']"
      :button-texts="['', '']"
      :format="{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}'
      }"
      @change="handleChange1"
      :data="data1">
        <!-- <span slot-scope="{ option }">{{ option.key }} - {{ option.label }}</span> -->
      </el-transfer>
    </Dialog>
    <!-- 批量停用 -->
    <Dialog dialog-title="批量停用" :is-show-dialog="endFlag" label-width="86px" dialog-width="640px" v-loading="loading" :flag="flagsure"
      @closeDialog="closeDialog" @submitForm="submitFormInfo3" ref="dia">
      <el-transfer
      style="text-align: left; display: inline-block"
      v-model="value2"
      filterable
      :left-default-checked="[2, 3]"
      :right-default-checked="[1]"
      :titles="['待选列表', '已选列表']"
      :button-texts="['', '']"
      :format="{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}'
      }"
      @change="handleChange2"
      :data="data2">
        <!-- <span slot-scope="{ option }">{{ option.key }} - {{ option.label }}</span> -->
      </el-transfer>
    </Dialog>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import Dialog from '@/components/Dialog';
import {
  hotelroomntist,
  batchSave,
  isolationpointupdate,
  updateStatus,
  getRoomCountInfoApi,
  getIsolationInfoApi,
  hotelRoomInfoApi,
  getHotelRoomByStatus,
  batchUpdateStatus,
  exportTemplate,
  uploadHotelRoomExcel,
  getHotelWarn,
  saveHotelWarn
} from "../../api/HotelManagement/roomManagement";
import { mapState } from 'vuex';
import moment from 'moment';
import { getToken } from "@/utils/auth";

export default {
  data() {
    const generateData1 = _ => {
        const data1 = [];
        for (let i = 1; i <= 15; i++) {
          data1.push({
            key: i,
            label: `备选项 ${ i }`,
            // disabled: i % 4 === 0
          });
        }
        return data1;
    };
    const generateData2 = _ => {
      const data2 = [];
        for (let i = 1; i <= 15; i++) {
          data2.push({
            key: i,
            label: `备选项 ${ i }`,
            // disabled: i % 4 === 0
          });
        }
        return data2;
    };
    return {
      data1: [],
      value1: [],
      filterMethod(query, item) {
        return item.pinyin.indexOf(query) > -1;
      },
      data2: [],
      value2: [],
      filterMethod(query, item) {
        return item.pinyin.indexOf(query) > -1;
      },
      renderFunc(h, option) {
          return <span>{ option.key } - { option.label }</span>;
      },
      phoneNumber1:null,
      number1:0,
      itemKey:0,
      pageData:{
        pageNumber:'',
        total:'',
        pageSize:''
      },
      loading:false,
      tableData: [],
      checkRecordList:[
        { 
          id:'',
          transshipmentHotelSeq:1,
          transshipmentHotel:'1'
        },
      ],
      checkRecordList2:[
        { 
          id:1,
          phoneNumber:'',
        },
        {
          id:2,
          phoneNumber:'',
        },
        {
          id:3,
          phoneNumber:'',
        },
        {
          id:4,
          phoneNumber:'',
        },
      ],
      radio: 1,
      queryvalue: "",
      isolationPointId: "",
      isArrange: false, //新增房间弹出
      isArrange02: false,
      options: [
        { label: "全部", value:""},
        { label: "已使用", value: 1 },
        { label: "空置", value: 2 },
        { label: "停用", value: 0 }
      ],
      redform: {
        labDays: [{labDaysName: ""}],
        status: "1"
      },
      redFormInfo: {},
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      rules: {
        floorNo: [{ required: true, message: "楼层号不能为空", trigger: "blur" }],
        status: [{ required: true, message: "请选择房间状态", trigger: "change" }],
        bedCount: [{ required: true, message: "请输入床位数", trigger: "blur" }]
      },
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      roles: JSON.parse(localStorage.getItem("roles")),
      isActive: null,
      isolationHospitalId: "",
      isolationOrgCode: "",
      totalNum: "-",  // 总数
      usableUseRoomNum: "-",  // 可用
      useRoomNum: "-",  // 已用
      unusedRoomNum: "-",  // 未用
      stopNum:'', //停用
      leaveUnusedNum:"", //脏房数量
      roomListInfo: [],
      roomWarniFlag:false,
      startFlag:false,
      endFlag:false,
      flag:false,
      // 上传文件请求头
      myHeaders: {
        jtoken: getToken(),
        clientType: "ADMIN",
        userType: "USER_DOCTOR",
        hospitalId:JSON.parse(localStorage.getItem("userdoctor")).hospitalId
      },
      upload: "/ohealth/api/v1/isolation/hotelroom/uploadHotelRoomExcel",
      flagsure:false,
      yijingId:''
    };
  },

  components:{
    Dialog
  },
  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList
    })
  },

  watch: {
    isolationList(val) {
      // 管理员权限走这个方法
      if (this.$btnPermission('008003001002')) {
        this.isolationPointId = val[0].id;
        this.isolationHospitalId = val[0].hospitalId;
        this.isolationOrgCode = val[0].orgCode;
        this.getRoomCountInfo();
        this.getIsolationInfo();
      }
    },
  },

  mounted() {
    // this.list();
    // 如果当前登录 是非管理员
    // if (this.roles.code === 'HOTEL_EMPLOYEE') {
    // if (this.$store.getters.permission.btnLists.contain('008003001001', 'permission')) {
    if (this.$btnPermission('008003001001')) {
      this.isolationHospitalId = this.userdoctor.hospitalId;
      this.isolationOrgCode = this.userdoctor.orgCode;
      this.getRoomCountInfo();
      this.getIsolationInfo();
    } else if (this.$btnPermission('008003001002')){
      // 如果是管理员
      if (this.isolationList && this.isolationList.length > 0) {
        this.isolationPointId = this.isolationList[0].id;
        this.isolationHospitalId = this.isolationList[0].hospitalId;
        this.isolationOrgCode = this.isolationList[0].orgCode;
        this.getRoomCountInfo();
        this.getIsolationInfo();
      }
    }
  },

  methods: {
    //文件上传成功
    handelSuccess(res, file, fileList) {
      if (res.code == 200) {
        if (this.$btnPermission('008003001001')) {
          this.isolationHospitalId = this.userdoctor.hospitalId;
          this.isolationOrgCode = this.userdoctor.orgCode;
          this.getRoomCountInfo();
          this.getIsolationInfo();
        } else if (this.$btnPermission('008003001002')){
          // 如果是管理员
          if (this.isolationList && this.isolationList.length > 0) {
            this.isolationPointId = this.isolationList[0].id;
            this.isolationHospitalId = this.isolationList[0].hospitalId;
            this.isolationOrgCode = this.isolationList[0].orgCode;
            this.getRoomCountInfo();
            this.getIsolationInfo();
          }
        }
        this.$message({
          message: "上传成功",
          type: "success",
        });
        // this.tempFileList.push({
        //   name: file.response.data.originalName,
        //   url: file.response.data.imgUrl,
        // });
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
      }
    },
    //文件上传失败
    handelError(error) {
      console.log(error);
      // if (res.code == 200) {
      //   this.$message({
      //     message: "上传失败",
      //     type: "error",
      //   });
      // }
    },
    // 上传excel
    uploadHotelRoomExcel(){

    },
    // 批量下载导入模板
    async donwload(){
        try {
          let { data } = await exportTemplate();
          let url = window.URL.createObjectURL(
              new Blob([data], { type: "application/x-download" })
          );
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", `房间批量录入导入模板.xlsx`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {}
    },
    // 获取房间列表
    getHotelRoomByStatus1(){
      // 	状态（0-停用 1-空置）
      let params1 = {
        status:0
      }
      let dataArr1 = []
      this.loading = true
      // 批量启用列表
      getHotelRoomByStatus({params:params1}).then(res=>{
        console.log(res.data.data);
        res.data.data.forEach(data=>{
          dataArr1.push(
            {
              key:data.id,
              label:`${data.floorNo}楼${data.roomNo}房间(${data.status == 0?'停用':data.status == 1?'空置':data.status == 2?'脏房':'暂无状态'})`
            }
          )
        })
        this.data1 = dataArr1
        this.loading = false
      })  
    },
    getHotelRoomByStatus2(){
      // 	状态（0-停用 1-空置）
      let params2 = {
        status:1
      }
      let dataArr2 = []
      this.loading = true
      // 批量停用列表
      getHotelRoomByStatus({params:params2}).then(res=>{
        res.data.data.forEach(data=>{
          dataArr2.push(
            {
              key:data.id,
              label:`${data.floorNo}楼${data.roomNo}房间(${data.status == 0?'停用':data.status == 1?'空置':data.status == 2?'脏房':'暂无状态'})`
            }
          )
        })
        this.data2 = dataArr2
        this.loading = false
      })
      
    },
    handleChange1(value, direction, movedKeys) {
        if(this.value1.length >0){
          this.flagsure = false
        }else{
          this.flagsure = true
        }
    },
    handleChange2(value, direction, movedKeys) {
        if(this.value2.length >0){
          this.flagsure = false
          console.log('5555');
        }else{
          this.flagsure = true
          console.log('6666');
        }
    },
    // 批量启动
    piliangqidong(){
      this.startFlag = true
      setTimeout(()=>{
        if(this.value1.length >0){
          this.flagsure = false
        }else{
          this.flagsure = true
        }
      },0)
      this.getHotelRoomByStatus1()
    },
    piliangtingyong(){
      this.endFlag = true
      setTimeout(()=>{
        if(this.value2.length >0){
          this.flagsure = false
        }else{
          this.flagsure = true
        }
      },0)
      this.getHotelRoomByStatus2()
    },
    // 电话号验证
    hendledianhua(data){
      let newArr = []
        this.checkRecordList2.forEach(res=>{
          if(res.phoneNumber == data){
            newArr.push(res.phoneNumber)
              if(newArr.length >= 2 && data != ''){
                this.$message({
                  message: '电话号码不能重复!',
                  type: 'warning'
                });
                res.phoneNumber = ''
                return
            }
          }
          if (res.phoneNumber.length != 11 && res.phoneNumber.length != 0) {
            this.$message({
                  message: '请输入11位电话号码!',
                  type: 'warning'
            });
            
          }
        }) 
    },
    queryChange2(data){
      setTimeout(()=>{
        let newArr = []
        this.checkRecordList.forEach(res=>{
          if(res.id == data){
            newArr.push(res.id)
              if(newArr.length >= 2 && data != ''){
                this.$message({
                  message: '转运酒店隔离点名称不能重复!',
                  type: 'warning'
                });
                res.id = ''
                return
            }
          }
          
        })
      },0)
    },
    // 提交
    submitFormInfo(){
      this.checkRecordList.forEach((res,index)=>{
        res.transshipmentHotelSeq = index + 1
      })
      this.checkRecordList2.forEach((res,index)=>{
        res.id = index + 1
      })
      let params = {
        hotelWarn: {
        earlyWarning: this.radio,
        hospitalId: JSON.parse(localStorage.getItem("userdoctor")).hospitalId,
        id: this.yijingId,
        linkPhoneFour: this.checkRecordList2[3].phoneNumber,
        linkPhoneOne: this.checkRecordList2[0].phoneNumber,
        linkPhoneThree: this.checkRecordList2[2].phoneNumber,
        linkPhoneTwo: this.checkRecordList2[1].phoneNumber,
        roomNum: this.number1,
      },
      sysOrgHotelList: this.checkRecordList
      }
      this.loading = true
      saveHotelWarn(params).then(res=>{
        if(res.data.code == 200){
            this.$message({
            message: '保存成功！',
            type: 'success'
          });
          this.roomWarniFlag = false
        }
        this.loading = false

      })
    },
    submitFormInfo2(){
      let params = {
        ids: this.value1,
        status: 1
      }
      this.loading = true
      batchUpdateStatus(params).then(res=>{
        if(res.data.code == 200){
            this.$message({
            message: '启动成功！',
            type: 'success'
          });
          // this.startFlag = false
          this.getHotelRoomByStatus1()
        }
        this.loading = false
        this.startFlag =false
        if (this.$btnPermission('008003001001')) {
        this.isolationHospitalId = this.userdoctor.hospitalId;
        this.isolationOrgCode = this.userdoctor.orgCode;
        this.getRoomCountInfo();
        this.getIsolationInfo();
      } else if (this.$btnPermission('008003001002')){
        // 如果是管理员
        if (this.isolationList && this.isolationList.length > 0) {
          this.isolationPointId = this.isolationList[0].id;
          this.isolationHospitalId = this.isolationList[0].hospitalId;
          this.isolationOrgCode = this.isolationList[0].orgCode;
          this.getRoomCountInfo();
          this.getIsolationInfo();
        }
      }
      })
    },
    submitFormInfo3(){
      let params = {
        ids: this.value2,
        status: 0
      }
      this.loading = true
      batchUpdateStatus(params).then(res=>{
        if(res.data.code == 200){
            this.$message({
            message: '停用成功！',
            type: 'success'
          });
          // this.endFlag = false
          this.getHotelRoomByStatus2()
          this.loading = false
           this.endFlag =false
        if (this.$btnPermission('008003001001')) {
        this.isolationHospitalId = this.userdoctor.hospitalId;
        this.isolationOrgCode = this.userdoctor.orgCode;
        this.getRoomCountInfo();
        this.getIsolationInfo();
      } else if (this.$btnPermission('008003001002')){
        // 如果是管理员
        if (this.isolationList && this.isolationList.length > 0) {
          this.isolationPointId = this.isolationList[0].id;
          this.isolationHospitalId = this.isolationList[0].hospitalId;
          this.isolationOrgCode = this.isolationList[0].orgCode;
          this.getRoomCountInfo();
          this.getIsolationInfo();
        }
      }
        }
      })
    },
    // 拖拽
    setSort() {
	  //ref一定跟table上面的ref一致
      const el = this.$refs.table1.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost",
        setData: function(dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        // 监听拖拽事件结束时触发
        onEnd: evt => {
          // 拖动行的前一行
          const targetRow = this.checkRecordList.splice(evt.oldIndex, 1)[0];
          // 拖动行的后一行
          this.checkRecordList.splice(evt.newIndex, 0, targetRow);
          //这里的checkRecordList是你table上面绑定的list
          console.log(this.checkRecordList); 
        }
      })
    },
    // 电话拖拽
    setSort2() {
	  //ref一定跟table上面的ref一致
      const el = this.$refs.table2.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost",
        setData: function(dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        // 监听拖拽事件结束时触发
        onEnd: evt => {
          // 拖动行的前一行
          const targetRow = this.checkRecordList2.splice(evt.oldIndex, 1)[0];
          // 拖动行的后一行
          this.checkRecordList2.splice(evt.newIndex, 0, targetRow);
          //这里的checkRecordList是你table上面绑定的list
          console.log(this.checkRecordList2); 
        }
      })
    },

    // 添加
    add(){
      let newArr = []
      this.checkRecordList.forEach(res=>{
        newArr.push(res.transshipmentHotelSeq)
      })
      var max = Math.max.apply(null,newArr);
      this.checkRecordList.push({
        transshipmentHotelSeq:max+1,
        id:'',
        transshipmentHotel:'1',
      })
    },
    changeValue(value) {
     this.number1 = /^[0-9]*$/.test(parseInt(value)) ? String(parseInt(value)).replace(".", "") : ""
    },
    // 酒店预警
    roomWarni(){
      this.roomWarniFlag = true
      
      setTimeout(()=>{
        this.setSort()
        this.setSort2()
      },0)
      this.loading = true
      getHotelWarn().then(res=>{
        this.checkRecordList = []
        res.data.data.sysOrgList.forEach(res=>{
          this.checkRecordList.push({
            id:res.id,
            transshipmentHotelSeq:res.transshipmentHotelSeq,
            transshipmentHotel:res.transshipmentHotel
          })
        })
        console.log(res.data.data);
        if(res.data.data.hotelWarn != null){
          if(res.data.data.hotelWarn.linkPhoneOne){
            this.checkRecordList2[0].phoneNumber = res.data.data.hotelWarn.linkPhoneOne+''
            this.checkRecordList2[1].phoneNumber = res.data.data.hotelWarn.linkPhoneTwo+''
            this.checkRecordList2[2].phoneNumber = res.data.data.hotelWarn.linkPhoneThree+''
            this.checkRecordList2[3].phoneNumber = res.data.data.hotelWarn.linkPhoneFour+''
          }
          this.radio = res.data.data.hotelWarn.earlyWarning
          this.number1 = res.data.data.hotelWarn.roomNum
          this.yijingId = res.data.data.hotelWarn.id
        }
        this.loading = false
        console.log(res.data.data);
        console.log(this.checkRecordList2);
      })
      
      // let element = this.$refs.table1
      // console.log(this.$refs);
      // console.log(this.$refs.table1);
      // this.setSort()
    },
    closeDialog() {
      if (this.roomWarniFlag) this.roomWarniFlag = false;
      if (this.startFlag) this.startFlag = false;
      if (this.endFlag) this.endFlag = false;
      // if (this.checkRecordDialog) this.checkRecordDialog = false;
    },
    // 删除
    delate(row){
      this.checkRecordList.forEach((res,index)=>{
        if(row.id == res.id){
          this.checkRecordList.splice(index, 1);
        } 
      })
    },
    // 统计数据
    getRoomCountInfo() {
      let params = {
        isolationHospitalId: this.isolationHospitalId,
        isolationOrgCode: this.isolationOrgCode
      }
      getRoomCountInfoApi(params).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          // 总房间数 totalNum;
          // 停用房间数 stopNum;
          // 使用房间数 useRoomNum;

          // 未用 = 总 - 使用的
          // 可用 = 未用 - 停用
          this.totalNum = data.totalNum;
          this.useRoomNum = data.useRoomNum;
          this.unusedRoomNum = this.totalNum - this.useRoomNum;
          this.usableUseRoomNum = data.usableUseRoomNum;
          this.leaveUnusedNum = data.leaveUnusedNum
          this.stopNum = data.stopNum
        } else {
          this.$message.error(msg);
        }
      })
    },

    // 房间管理信息
    getIsolationInfo() {
      let params = {
        isolationPointId: this.isolationPointId,
        isolationHospitalId: this.isolationHospitalId,
        isolationOrgCode: this.isolationOrgCode
      };
      getIsolationInfoApi(params).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          data.forEach(item => {
            item.roomList.forEach(key => {
              if (key.status == 0) {
                key.statusName = '停用';
              } else if (key.status == 2) {
                key.statusName = '脏房';
              } else if (key.livedCount >= key.allCount) {
                key.statusName = "满员";
              } else if (key.livedCount > 0 && key.livedCount < key.allCount) {
                key.statusName = "已使用";
              } else if (key.livedCount == 0) {
                key.statusName = '空置';
              }

              // 计算离开房间的时间
              if (key.leaveTime) {
                let day3 = moment(key.leaveTime).add(3, 'days').format('YYYY-MM-DD HH:mm:ss')
                let greaterTimeTimestamp = new Date(day3).getTime()
                let currentTimestamp = new Date().getTime()

                // 当前时间 > 离开房间时间 加三天，就显示气泡
                key.isShow =  currentTimestamp > greaterTimeTimestamp ? true : false;
              }
            })
          })
          this.roomListInfo = data;
        } else {
          this.$message.error(msg);
        }
      })
    },

    // 点击新增房间
    addroom() {
      this.isArrange = true;
      this.redform = {
        labDays: [{ labDaysName: "" }],
        status: "1"
      };
    },

    removeDomain(index) {
      this.redform.labDays.splice(index, 1);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.list();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.list();
    },
    addDomain() {
      this.redform.labDays.push({});
    },
    // 房间保存
    fjbc() {
      let form = this.redform;
      let a = JSON.parse(localStorage.getItem("userdoctor"));
      const nodes = form.labDays.map(item => ({
        roomNo: item.labDaysName
      }));
      for (let i in nodes) {
        nodes[i].bedCount = form.bedCount;
        nodes[i].remark = form.remark;
        nodes[i].windowFlag = form.windowFlag;
        nodes[i].floorNo = form.floorNo;
        nodes[i].hospitalId = a.hospitalId;
        nodes[i].orgCode = a.orgCode;
      }

      this.$refs.redformRef.validate( async valid => {
        if (valid) {
          let params = { hotelRooms: nodes };
          let result = await batchSave(params);
          let { code, data, msg } = result.data;
          if (code == 200) {
            this.isArrange = false;
            this.$message.success(msg)
            this.getIsolationInfo();
            this.getRoomCountInfo();
          }
        }
      });
    },
    // 房间修改
    async fjbc02() {
      this.$refs.redformRef2.validate( async valid => {
        if (valid) {
          let params = { ...this.redFormInfo };
          let { data } = await isolationpointupdate(params);
          if (data.code == 200) {
            this.$message.success("修改成功");
            this.isArrange02 = false;
            this.getIsolationInfo();
          }
        }
      });
    },
    // 查询列表
    async list() {
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };

      if (this.queryvalue) params.state = this.queryvalue;
      if (this.isolationPointId) params.isolationPointId = this.isolationPointId;

      let { data } = await hotelroomntist({ params });
      if (data.code == 200) {
        this.tableData = data.data.list;
        this.total = data.data.totalRow;
      } else {
        this.$message.error(data.msg);
      }
    },

    // 编辑
    arrange(row) {
      // console.log(11111111111,this.$store.getters.permission.btnLists);
      // 酒店职员才可以编辑
      if (this.$store.getters.permission.btnLists.contain('008003001003', 'permission')) {
        this.isArrange02 = true;
        let params = {
          id: row.hotelRoomId
        };
        hotelRoomInfoApi({ params }).then(res => {
          let { code, data, msg } = res.data;
          if (code == 200) {
            this.redFormInfo = data;
          } else {
            this.$message.error(msg)
          }
        })
      }else{
        console.log('没有权限');
      }
    },
    // 停用
    async dictionaryFun(row) {
      let status = 0;
      if (row.status == 1 || row.status == 2) {
        status = 0;
      } else {
        status = 2;
      }
      let params = {
        id: row.id,
        status: status
      };
      let { data } = await updateStatus(params);
      if (data.code == 200) {
        this.list();
        this.$forceUpdate();
      } else {
        this.$message.error(data.msg)
      }
    },
    // 隔离点查询
    queryChange() {
      this.isolationList.forEach(item => {
        if (item.id == this.isolationPointId) {
          this.isolationHospitalId = item.hospitalId;
          this.isolationOrgCode = item.orgCode;
        }
      })

      this.getRoomCountInfo();
      this.getIsolationInfo();
    }
  }
};
</script>

<style lang='scss' scoped>
@import "../../static/css/room-list.scss";

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.top_btn {
  display: flex;
  justify-content: space-between;
}
.content_page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}
.content {
  flex: 1;
  // overflow-x: hidden;
  // overflow-y: scroll;
}

.arrange_content_bottom{
  margin-top: 10px;
  overflow: visible;
  height: 100%;
}

//统计数据
.census-box{
  display: flex;
  margin-top: 20px;

  .census-item{
    width: 25%;
    border-radius: 10px;
    margin-right: 30px;
    padding: 12px 18px;
    box-sizing: border-box;
    color: #ffffff;

    &:nth-child(1) {
      background-image: linear-gradient(140deg, #4cd9ff, #00c9ff);
    }

    &:nth-child(2) {
      background: linear-gradient(140deg, #3fefd0, #18d3b2);
    }

    &:nth-child(3) {
      background: linear-gradient(140deg, #e097ff, #bd4ced);
    }

    &:nth-child(4) {
      background: linear-gradient(140deg, #F9B382, #eb8843);
    }

    &:nth-child(5) {
      background: linear-gradient(140deg, #fa9f9f, #ff6969);
    }

    &:nth-child(6) {
      margin-right: 0;
      background: linear-gradient(140deg, #fbcd6b , #fcb626);
    }

    .census-title{
      font-size: 15px;
    }

    .census-num{
      font-size: 40px;
      font-weight: bold;
    }
  }
}
.btnbox{
  display: flex;
}
.btn{
  margin-left: 20px;
}
.transfer-footer {
    margin-left: 20px;
    padding: 6px 5px;
  }
</style>
